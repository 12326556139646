import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import AOS from "aos";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useInView } from "react-hook-inview";
import { loadScript } from "../../../helper";
import c1 from "../../../assets/images/banner/c1.png";
import c2 from "../../../assets/images/banner/c2.png";
import c3 from "../../../assets/images/banner/c3.png";
import stars from "../../../assets/images/banner/stars.svg";
import quote from "../../../assets/images/banner/quote.svg";
const markets_slider = [
  {
    head: "SJR",
    sub: "Hosur Market",
  },
  {
    head: "MNR",
    sub: "Kolar Market",
  },
  {
    head: "K. A.",
    sub: "KR Market",
  },
  {
    head: "RSA",
    sub: "Kolar Market",
  },
  {
    head: "SPM",
    sub: "Salem Market",
  },
  {
    head: "400+",
    sub: "Customers",
  },
];
const cust_slider = [
  {
    header: "Deepak Kumar",
    para: "ONO has now become a daily habit for me. I check the ONO app daily for the prices and share the price information with my friends too. Now I use only the ONO app for price updates and have got rid of all other apps. Best thing with Ono app is that the price gets updated daily and I can trust ONO app for accurate prices across all markets.",
    sub: "Trader",
    image: c1,
  },
  {
    header: "Hemen Verma ",
    para: "ONO app benefited me a lot. I am constantly updated on the price for my produce. Not only I can now compare the prices of nearby mandis during the day but I can keep a look at the prices for more than two days. This gives me a clear idea of where I should be selling my produce for maximum profits. The most important benefit with the app is that the price gets updated daily and the accuracy level is also very high. Hence I am rest assured that I will be getting true value for my produce. Sometimes I share the  price updates with my friends too. I highly recommend the ONO app to all my friends.",
    sub: "Farmer",
    image: c2,
  },
  {
    header: "Abhay Pratap Singh ",
    para: "I earned Rs 12 more per kg on my agricultural produce by selling at the right mandi! Thanks to the ONO app for helping me get the right price of my yield. Earlier my father used to sell vegetables at a nearby mandi and would earn so much less. This time however we took our product to the right market and we earned so much more! ONO helped me to reach the correct market at the right time and I ended up earning 80% more this season. Thank you ONO.",
    sub: "Farmer",
    image: c3,
  },
];
const OurPresence = () => {
  const [ref, inView] = useInView();
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    AOS.init();
  }, []);
  useEffect(() => {
    if (loaded === false && inView) {
      setLoaded(true);
      (async () => {
        await loadScript("js/mapdata.js");
        await loadScript("js/countrymap.js");
      })();
    }
  }, [inView]);
  return (
    <div ref={ref}>
      {!loaded ? (
        <></>
      ) : (
        <div className="loc_customer">
          <div className="presence_sec common_sec_padding pt-0">
            <div className="container">
              <div className="d-flex align-items-center">
                <div className="border_line"></div>
                <h2 className="section_head1">Our Presence</h2>
                <div className="border_line"></div>
              </div>
              <div className="presence_row common_row" id="inview-example">
                <div className="row align-items-center">
                  <div
                    className="col-lg-2 pl-0 numbers_coll"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <div>
                      <h5
                        className="section_head counter-count timer"
                        data-count="25"
                      >
                        580+
                      </h5>
                      <p className="body_para upper_leter text_bold">
                        COMMISSION AGENTS/traders
                      </p>
                    </div>
                    <div>
                      <h5 className="section_head">73k+</h5>
                      <p className="body_para upper_leter text_bold">farmers</p>
                    </div>
                    <div>
                      <h5 className="section_head">218+</h5>
                      <p className="body_para upper_leter text_bold">MANDIS</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div id="map"></div>
                  </div>
                  <div
                    className="col-lg-4"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    <h2 className="section_head mb-2">Impact in India</h2>
                    <p className="body_para">
                      Our multi-sided platform is driving the real impact across
                      the Agri ecosystem through transparency, supply and demand
                      visibility, price intelligence, market discovery and
                      real-time data access.
                    </p>
                    <div className="d-flex number_row">
                      <div>
                        <h5 className="section_head">304</h5>
                        <p className="body_para upper_leter text_bold">
                          DISTRICTS
                        </p>
                      </div>
                      <div className="ml-5">
                        <h5 className="section_head">13</h5>
                        <p className="body_para upper_leter text_bold">
                          STATES
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="customers">
            <div className="container">
              <div className="d-flex align-items-center">
                <div className="border_line white_border"></div>
                <h2 className="section_head1 color_white">Success stories</h2>
                <div className="border_line white_border"></div>
              </div>
              <div className="stories_row common_row">
                <OwlCarousel
                  items={1}
                  className="owl-theme banner_car cust_car"
                  margin={20}
                  loop
                  dots={false}
                  nav
                  // autoplay={true}
                  autoplayTimeout={5000}
                  autoplayHoverPause={true}
                  autoHeight={true}
                  // autoHeightClass="owl-height"
                >
                  {cust_slider.map((item, i) => {
                    return (
                      <div className="item">
                        <div className="row">
                          <div className="col-lg-4">
                            <img
                              src={item.image}
                              className="customer"
                              alt="customer"
                            />
                          </div>
                          <div className="col-lg-8">
                            <div className="d-flex align-items-start">
                              <div>
                                <h2 className="section_head mr-3 color_white">
                                  {item.header}
                                </h2>
                                <p className="body_para">{item.sub}</p>
                              </div>
                              <img src={stars} alt="stars" />
                            </div>
                            <p className="sub_para">{item.para}</p>
                            <img src={quote} alt="stars" />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </OwlCarousel>
              </div>
              <div className="d-flex align-items-center common_row">
                <div className="border_line white_border"></div>
                <h2 className="section_head1 color_white"> More customers </h2>
                <div className="border_line white_border"></div>
              </div>
              <div className="market_names">
                <div className="row">
                  {markets_slider.map((item, i) => {
                    return (
                      <div className="col-lg-2 p-0">
                        <div
                          className="card final"
                          data-aos="flip-left"
                          data-aos-easing="ease-out-cubic"
                          data-aos-duration="1000"
                        >
                          <h2 className="section_head color_white">
                            {item.head}
                          </h2>
                          <p className="body_para">{item.sub}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default OurPresence;
