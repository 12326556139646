import { useState } from "react";
import { contactUsService } from "../actions/contactService";
import cta_img from "../assets/images/banner/cta_img.svg";
import "../components/call_to_action.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const CalltoAction = (props) => {
  const [nameField, setNameField] = useState("");
  const [requiredNameField, setRequiredNameField] = useState("");
  const [mobileNumber, setmobileNumber] = useState("");
  const [requiredNumberField, setRequiredNumberField] = useState("");
  const [emaiField, setEmailField] = useState("");
  const [messageField, setMessageField] = useState("");
  const [nameError, setNameError] = useState("");
  const handleName = (e) => {
    setNameField(e.target.value.replace(/[^A-Za-z0-9]/g, " "));
    setRequiredNameField("");
  };
  const handleEmail = (e) => {
    setEmailField(e.target.value.replace(/[^A-Za-z0-9]/g, " "));
  };
  const handleMessage = (e) => {
    setMessageField(e.target.value.replace(/[^A-Za-z0-9]/g, " "));
  };
  const handleMobileNumber = (e) => {
    let onlyNumbers = e.target.value.replace(/[^\d]/g, "");
    if (e.target.value.length < 10) {
      setRequiredNumberField("Minimum mobile number length should be 10");
    } else {
      setRequiredNumberField("");
    }
    let number = onlyNumbers.slice(0, 10);
    setmobileNumber(number);
  };
  const onSubmit = () => {
    if (
      nameField.trim().length !== 0 &&
      nameField.trim().length !== 0 &&
      mobileNumber.trim().length >= 10
    ) {
      apiCall();
    } else if (nameField.trim().length === 0) {
      setRequiredNameField("Enter Name");
    } else if (nameField.trim().length === 1) {
      setNameError("Name should be min 3 characters");
    } else if (mobileNumber.trim().length < 10) {
      setRequiredNumberField("Minimum mobile number length should be 10");
    } else if (mobileNumber.trim().length === 0) {
      setRequiredNumberField("Enter your contact number");
    }
  };
  var obj = {
    name: nameField,
    email: "email",
    mobileNumber: "mobileNumber",
    message: "message",
  };
  const apiCall = () => {
    contactUsService(obj).then(
      (response) => {
        if (response.status == "201") {
          toast.success(response.data.data.statusDesc, {
            toastId: "success2",
          });
        }
      },
      (error) => {
        toast.error(error.response.data.data.statusDesc, {
          toastId: "errorr3",
        });
      }
    );
  };
  return (
    <section
      className="contactpage_sec common_sec_padding"
      id="contact_home"
      style={{ backgroundColor: props.color }}
    >
      <div className="container">
        <div className="white_head text-center">
          <p className="section_head1 color_white text-center section_head1_action">
            Reach Out to Us
          </p>
          <h2 className="section_head color_white">
            We look forward to hearing from you!
          </h2>
        </div>
        <div className="row contactsec_row align-items-center">
          <div className="col-lg-7 col-xs-12">
            <img
              src={cta_img}
              className="d-flex mx-auto cta_manimg"
              alt="ono-image"
            />
          </div>
          <div className="col-lg-5 col-xs-12">
            <form id="basic-form" method="">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  value={nameField}
                  placeholder="Name*"
                  name="name"
                  onChange={(e) => {
                    handleName(e);
                  }}
                />
                <span className="text-danger">{requiredNameField}</span>
                <span className="text-danger">{nameError}</span>
              </div>
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  value={mobileNumber}
                  id="phonenumber"
                  placeholder="Contact Number*"
                  name="phonenumber"
                  onChange={(e) => {
                    handleMobileNumber(e);
                  }}
                />
                <span className="text-danger">{requiredNumberField}</span>
              </div>
              <div class="form-group">
                <input
                  type="email"
                  class="form-control"
                  value={emaiField}
                  id="email"
                  placeholder="Email ID"
                  name="email"
                  onChange={(e) => {
                    handleEmail(e);
                  }}
                />
              </div>
              <div class="form-group">
                <textarea
                  class="form-control"
                  id="message"
                  value={messageField}
                  placeholder="Message"
                  rows="3"
                  name="message"
                  onChange={(e) => {
                    handleMessage(e);
                  }}
                ></textarea>
              </div>
              <button
                class="submitbtn"
                type="button"
                onClick={() => onSubmit()}
              >
                Submit
              </button>
            </form>
            <div class="thank-you">
              <h4 class="text-center">
                Thank you for contacting us.<p></p>
              </h4>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </section>
  );
};
export default CalltoAction;
