import React, { useEffect, Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./modules/home";
import Footer from "./components/footer";
import Headers from "./components/header";
import { useLocation } from "react-router";
import { cacheImages, emptyCache } from "./helper";
import packageJson from "../package.json";
import bg1 from "./assets/images/banner/bg1.png";
import bg2 from "./assets/images/banner/bg2.png";
import bg3 from "./assets/images/banner/bg3.png";
const About = lazy(() => import("./modules/about"));
const Careers = lazy(() => import("./modules/careers"));
const DesignTeam = lazy(() => import("./modules/design_team"));
const Openings = lazy(() => import("./modules/openings"));
const EngineeringTeam = lazy(() => import("./modules/engineering_team"));
const MarketingTeam = lazy(() => import("./modules/marketing"));
const SalesTeam = lazy(() => import("./modules/sales"));
const ContactUs = lazy(() => import("./modules/contact_us"));
const Connect = lazy(() => import("./modules/connect"));
const Click = lazy(() => import("./modules/click"));
const Mandi = lazy(() => import("./modules/mandi"));
const Cash = lazy(() => import("./modules/cash"));
const PrivacyPolicy = lazy(() => import("./modules/privacy_policy"));
const ConditionsOfUse = lazy(() => import("./modules/Conditions_of_use"));
const AllProducts = lazy(() => import("./modules/all_products"));
const Report = lazy(() => import("./modules/report"));
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  return null;
}
let images = [bg1, bg2, bg3];
cacheImages(images);
// emptyCache(packageJson.version);
function App() {
  return (
    <div className="App">
      <Headers />
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
      <ScrollToTop />
      <Suspense fallback={<div>loading ....</div>}>
        <Routes>
          <Route path="/company" element={<About />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/openings" element={<Openings />} />
          <Route path="/design-team" element={<DesignTeam />} />
          <Route path="/engineering-team" element={<EngineeringTeam />} />
          <Route path="/marketing" element={<MarketingTeam />} />
          <Route path="/sales" element={<SalesTeam />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/connect" element={<Connect />} />
          <Route path="/click" element={<Click />} />
          <Route path="/mandi" element={<Mandi />} />
          <Route path="/cash" element={<Cash />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/conditions-of-use" element={<ConditionsOfUse />} />
          <Route path="/all-products" element={<AllProducts />} />
          <Route path="/2024" element={<Report />} />
        </Routes>
      </Suspense>
      <Footer />
    </div>
  );
}

export default App;
